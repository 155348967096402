import React, { useState, useEffect } from 'react';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { Input, Button, Modal, Typography, message } from 'antd';
import jsonp from 'jsonp';
import Cookies from 'js-cookie';
import Logo from '../util/logo';

const { Title, Paragraph, Link } = Typography;

message.config({
  top: 100,
  duration: 2,
  maxCount: 3, 
});

const MailchimpPopup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const modalStatus = Cookies.get('modalStatus');
  useEffect(() => {
    // Check if the cookie is set, if not, open the modal
    if (!modalStatus) {
      const timer = setTimeout(() => {
        setIsModalOpen(true);
      }, 1000); // 1 second delay
      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [modalStatus]);
  
  const styles = {
    container: {
      backgroundColor: '#feeeec',
    },
    typographyContainer: {
      textAlign: 'center', // Align all typography to the center
    },
    modalInput: {
      marginTop: '16px',
    },
    submitButton: {
      width: '100%',
      height: '38px',
      backgroundColor: 'black',
      borderColor: 'black',
      color: 'white',
      marginTop: '16px',
    },
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    if(!modalStatus){
      Cookies.set('modalStatus', 'closed', { expires: 7 });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const url = 'https://chillfitrave.us21.list-manage.com/subscribe/post?u=7c5333982cb6d4bbf67498190&amp;id=d3cafead0c&amp;f_id=00c987e6f0';
    jsonp(`${url}&EMAIL=${email}&FNAME=${name}`, { param: 'c' }, (_, data) => {
      const { result, msg } = data;
      if(name.length === 0){
        message.open({
          type: 'warning',
          content: 'Please enter your first name.',
        });
        setIsSubmitting(false);
      }
      else if(email.length === 0){
        message.open({
          type: 'warning',
          content: 'Please enter your e-mail address.',
        });
        setIsSubmitting(false);
      }
      else if(result === 'success'){
        messageApi.open({
          type: 'success',
          content: msg + ' Check your email for a special one time use code for discount!',
        });
        Cookies.set('modalStatus', 'submitted', { expires: 365 });
        setIsSubmitting(false);
        setIsModalOpen(false);
      }
      else if(result === 'error'){
        message.open({
          type: 'error',
          content: msg,
        });
        setIsSubmitting(false);
      }
      else{
        message.open({
          type: 'warning',
          content: msg,
        });
        setIsSubmitting(false);
      }
    });
  };

  return (
    <>
      <Modal centered open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Typography style={styles.typographyContainer}>
          <Link href='/'>
            <Logo />
          </Link>
          <Title level={3}>NEVER MISS A NEW OR A DEAL</Title>
          <Paragraph>Subscribe for 15% OFF for your first order</Paragraph>
        </Typography>
        <div style={styles.modalInput}>
          <Input size="large" placeholder="First name" prefix={<UserOutlined />} value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div style={styles.modalInput}>
          <Input size="large" placeholder="E-mail address" prefix={<MailOutlined />} value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          {contextHolder}
          <Button type="primary" style={styles.submitButton} onClick={handleSubmit} loading={isSubmitting}>
            Subscribe
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MailchimpPopup;
